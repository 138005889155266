const views = [
  // ---------------------------------------------------------------------------------------------------------------
  // ——— Welcome ——————————————————
  {
    path: '/views/welcome',
    name: 'views-welcome',
    component: () => import('@/views/welcome/Welcome.vue'),
    meta: {
      layout: 'content',
      allow: true,
    },
  },

  // ——— Dashboard ——————————————————
  {
    path: '/views/dashboard',
    name: 'views-dashboard',
    component: () => import('@/views/dashboards/dashboard/Dashboard.vue'),
    meta: {
      layout: 'content',
      id: 14,
    },
  },

  // ---------------------------------------------------------------------------------------------------------------
  // ——— Basic CRUD ——————————————————
  {
    path: '/views/basic/list',
    name: 'views-basic-list',
    component: () => import('@/views/basic-crud/BasicList.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 11,
    },
  },
  {
    path: '/views/basic/form/:id?',
    name: 'views-basic-form',
    component: () => import('@/views/basic-crud/BasicForm.vue'),
    meta: {
      layout: 'content',
      id: 11,
    },
  },

  // Dispatchs Views ---------------------------------------------------------------------------------------------------------------
  // ——— Dispatchs ——————————————————
  {
    path: '/views/dispatch/list',
    name: 'views-dispatch-list',
    component: () => import('@/views/dispatchs-views/dispatchs/DispatchList.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 1,
    },
  },
  {
    path: '/views/dispatch/form/:id?',
    name: 'views-dispatch-form',
    component: () => import('@/views/dispatchs-views/dispatchs/DispatchForm.vue'),
    meta: {
      layout: 'content',
      id: 1,
    },
  },

  // ——— Reports View ——————————————————
  {
    path: '/views/reports/view',
    name: 'views-reports-view',
    component: () => import('@/views/dispatchs-views/reports-view/ReportsView.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: [14],
    },
  },

  // Product Views ---------------------------------------------------------------------------------------------------------------
  // ——— Products ——————————————————
  {
    path: '/views/product/list',
    name: 'views-product-list',
    component: () => import('@/views/product-views/products/ProductList.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 2,
    },
  },
  {
    path: '/views/product/form/:id?',
    name: 'views-product-form',
    component: () => import('@/views/product-views/products/ProductForm.vue'),
    meta: {
      layout: 'content',
      id: 2,
    },
  },

  // Security Views ---------------------------------------------------------------------------------------------------------------
  // ——— Roles ——————————————————
  {
    path: '/views/role/list',
    name: 'views-role-list',
    component: () => import('@/views/security-views/roles/RoleList.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 11,
    },
  },
  {
    path: '/views/role/form/:id?',
    name: 'views-role-form',
    component: () => import('@/views/security-views/roles/RoleForm.vue'),
    meta: {
      layout: 'content',
      id: 11,
    },
  },

  // ——— User ——————————————————
  {
    path: '/views/user/list',
    name: 'views-user-list',
    component: () => import('@/views/security-views/users/UserList.vue'),
    meta: {
      layout: 'content',
      id: 12,
    },
  },
  {
    path: '/views/user/form/:id?',
    name: 'views-user-form',
    component: () => import('@/views/security-views/users/UserForm.vue'),
    meta: {
      layout: 'content',
      id: 12,
    },
  },

  // ——— Notifications ——————————————————
  {
    path: '/views/console-notification/list',
    name: 'views-console-notification-list',
    component: () => import('@/views/security-views/console-notifications/ConsoleNotificationList.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 13,
    },
  },
  {
    path: '/views/console-notification/form/:id?',
    name: 'views-console-notification-form',
    component: () => import('@/views/security-views/console-notifications/ConsoleNotificationForm.vue'),
    meta: {
      layout: 'content',
      id: 13,
    },
  },

  // Product Views ---------------------------------------------------------------------------------------------------------------
  // ——— Mixtures ——————————————————
  {
    path: '/views/mix-type/list',
    name: 'views-mix-type-list',
    component: () => import('@/views/product-views/mixtures/MixtureList.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 5,
    },
  },
  {
    path: '/views/mix-type/form/:id?',
    name: 'views-mix-type-form',
    component: () => import('@/views/product-views/mixtures/MixtureForm.vue'),
    meta: {
      layout: 'content',
      id: 5,
    },
  },

  // Customer Views ---------------------------------------------------------------------------------------------------------------
  // ——— Payments ——————————————————
  {
    path: '/views/payment/list',
    name: 'views-payment-list',
    component: () => import('@/views/customer-views/payments/payment-list/PaymentList.vue'),
    meta: {
      layout: 'content',
      id: 10,
    },
  },
  {
    path: '/views/payment/preview/:id',
    name: 'views-payment-preview',
    component: () => import('@/views/customer-views/payments/payment-preview/PaymentPreview.vue'),
    meta: {
      layout: 'content',
      id: 10,
    },
  },
  {
    path: '/views/payment/form/:id?',
    name: 'views-payment-form',
    component: () => import('@/views/customer-views/payments/payment-preview/PaymentForm.vue'),
    meta: {
      layout: 'content',
      id: 10,
    },
  },

  // ——— Clients ——————————————————
  {
    path: '/views/client/list',
    name: 'views-client-list',
    component: () => import('@/views/customer-views/customers/CustomerList.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 6,
    },
  },
  {
    path: '/views/client/form/:id?',
    name: 'views-client-form',
    component: () => import('@/views/customer-views/customers/CustomerForm.vue'),
    meta: {
      layout: 'content',
      id: 6,
    },
  },

  // ——— Projects ——————————————————
  {
    path: '/views/project/list',
    name: 'views-project-list',
    component: () => import('@/views/customer-views/projects/ProjectList.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 7,
    },
  },
  {
    path: '/views/project/form/:id?',
    name: 'views-project-form',
    component: () => import('@/views/customer-views/projects/ProjectForm.vue'),
    meta: {
      layout: 'content',
      id: 7,
    },
  },

  // ——— Construction Sites ——————————————————
  {
    path: '/views/construction-site/list',
    name: 'views-construction-site-list',
    component: () => import('@/views/customer-views/construction-sites/ConstructionSiteList.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 7,
    },
  },
  {
    path: '/views/construction-site/form/:id?',
    name: 'views-construction-site-form',
    component: () => import('@/views/customer-views/construction-sites/ConstructionSiteForm.vue'),
    meta: {
      layout: 'content',
      id: 7,
    },
  },

  // ——— Vehicles ——————————————————
  {
    path: '/views/vehicle/list',
    name: 'views-vehicle-list',
    component: () => import('@/views/customer-views/vehicles/VehicleList.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 8,
    },
  },
  {
    path: '/views/vehicle/form/:id?',
    name: 'views-vehicle-form',
    component: () => import('@/views/customer-views/vehicles/VehicleForm.vue'),
    meta: {
      layout: 'content',
      id: 8,
    },
  },

  // ——— Drivers ——————————————————
  {
    path: '/views/driver/list',
    name: 'views-driver-list',
    component: () => import('@/views/customer-views/drivers/DriverList.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 9,
    },
  },
  {
    path: '/views/driver/form/:id?',
    name: 'views-driver-form',
    component: () => import('@/views/customer-views/drivers/DriverForm.vue'),
    meta: {
      layout: 'content',
      id: 9,
    },
  },
]

export default views
