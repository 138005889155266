import { mdiCheckboxBlankCircleOutline } from '@mdi/js'

const pack = require('./package.json')

const themeConfig = {
  app: {
    name: 'TEST',
    logo: require('@/assets/images/logos/test-logo.png'),
    description: 'TEST™ This is the base template for a large project.',
    isDark: false,
    isRtl: false,
    contentLayoutNav: 'vertical', // vertical, horizontal
    routeTransition: 'scroll-x-transition', // https://vuetifyjs.com/en/styles/transitions/#api
    // ! `semi-dark` isn't available for horizontal nav menu
    skinVariant: 'default', // default, bordered, semi-dark
    contentWidth: 'boxed',
    version: pack.version, // 1.0.12
  },
  menu: {
    isMenuHidden: false,
    isVerticalNavMini: false,
    verticalMenuAccordion: true,
    groupChildIcon: mdiCheckboxBlankCircleOutline,
    horizontalNavMenuGroupOpenOnHover: true,
  },
  appBar: {
    /*
    ! In Content Layout Horizontal Nav type `hidden` value won't work
    ! In Content Layout Horizontal Nav type value of `type` will affect both Appbar and Horizontal Navigation Menu
    */
    type: 'fixed', // fixed, static, hidden
    isBlurred: true,
  },
  footer: {
    type: 'hidden', // fixed, static, hidden
  },
  themes: {
    light: {
      primary: '#979797', // #FF3232' '#9155FD',
      accent: '#0d6efd',
      secondary: '#007d5c', // '#8A8D93',
      success: '#56CA00',
      info: '#16B1FF',
      warning: '#FFB400',
      error: '#FF4C51',

      red: '#F44336',
      orange: '#FF9800',
      yellow: '#FFEB3B',
      green: '#4CAF50',
      blue: '#2196F3',
      purple: '#9C27B0',
      black: '#000000',
      moreDarkGray: '#d7d7d7',
      darkGray: '#1D1D1D',
      lightGray: '#9F9F9F',
      white: '#FFFFFF',

      recurring: '#5EE076',
      regular: '#C15F9B',
      group: '#FD8399',
      other: '#F23F48',
      pickUp: '#FFA17A',
      academy: '#76C4F4',
      match: '#C3BB4E',
      league: '#FF6F91',
      birthday: '#B9CC1B',
      tournament: '#F8CD52',
      blocked: '#3F3F3F',

      revervation: '#919191',
      close: '#8A8D93',
      dialig: '#FFFFFF',
      shareIcon: '#3A3541',

      paid: '#13CE66',
      hold: '#00A6FF',
      pending: '#FFD185',
      cancelled: '#FF3232',
    },
    dark: {
      primary: '#DCDCDC', // #FF3232',
      accent: '#0d6efd',
      secondary: '#007d5c',
      success: '#56CA00',
      info: '#16B1FF',
      warning: '#FFB400',
      error: '#FF4C51',

      red: '#F44336',
      orange: '#FF9800',
      yellow: '#FFEB3B',
      green: '#4CAF50',
      blue: '#2196F3',
      purple: '#9C27B0',
      black: '#000000',
      moreDarkGray: '#353232',
      darkGray: '#1D1D1D',
      lightGray: '#9F9F9F',
      white: '#FFFFFF',

      recurring: '#5EE076',
      regular: '#C15F9B',
      group: '#FD8399',
      other: '#F23F48',
      pickUp: '#FFA17A',
      academy: '#76C4F4',
      match: '#C3BB4E',
      league: '#FF6F91',
      birthday: '#B9CC1B',
      tournament: '#F8CD52',
      blocked: '#3F3F3F',

      revervation: '#919191',
      close: '#FFFFFF',
      dialog: '#222222',
      shareIcon: '#FFFFFF',

      paid: '#13CE66',
      hold: '#00A6FF',
      pending: '#FFD185',
      cancelled: '#FF3232',
    },
  },
}

export default themeConfig
